import React from 'react';

import SVG, { svgProps } from './svg';

const CalendarCheck = ({
  className,
  height = '40',
  title = 'CalendarCheck',
  width = '40',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 33 33"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path fill="#94A2A7" d="M25 0c2.761 0 5 2.239 5 5v11c-.615-.463-1.285-.856-2-1.168V10c0-1.105-.895-2-2-2H4c-1.105 0-2 .895-2 2v15c0 1.657 1.343 3 3 3h9.832c.312.715.705 1.385 1.168 2H5c-2.761 0-5-2.239-5-5V5c0-2.761 2.239-5 5-5h20zM6.75 22.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25zm5.5-5.5c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25S11 18.69 11 18s.56-1.25 1.25-1.25zm-5.5 0c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25S5.5 18.69 5.5 18s.56-1.25 1.25-1.25zm5.5-5.5c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25S11 13.19 11 12.5s.56-1.25 1.25-1.25zm5.5 0c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z" transform="translate(-171 -294) translate(0 154) translate(146 115) translate(25.5 25.5)"/>
              <path fill="#00CC96" d="M24 16c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm4.04 4.903c-.355-.354-.911-.381-1.296-.082l-.093.082-3.766 3.766-1.536-1.536c-.354-.354-.91-.381-1.296-.082l-.092.082-.174.173c-.353.354-.38.91-.081 1.295l.081.093 2.404 2.403c.354.354.91.381 1.295.082l.093-.082 4.634-4.633c.353-.354.38-.91.081-1.296l-.081-.092-.174-.173z" transform="translate(-171 -294) translate(0 154) translate(146 115) translate(25.5 25.5)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

CalendarCheck.propTypes = svgProps;

export default CalendarCheck;
