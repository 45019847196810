import React from "react";

import { getDuration } from '../../utils/datefns';
import { getOwnerName } from '../../utils/naming';
import { pluralize } from '../../utils/wording';

import '../icons/wrapper.css';
import AvatarMain from '../avatar-main';

import './availabilityDetails.css';
import FromWhom from "../icons/from-whom";
import Clock from "../icons/clock";

const EventDetails = ({ availability = {}, type = '' }) => {
  const { participants = [], options = [] } = availability;
  const owner = participants.find((participant) => participant.isOwner);
  const duration = options.length > 0 ? getDuration(options[0].start, options[0].end) : '0 minute';
  const numberOfOptions = options.reduce((count, { events = []}) => count + events.length, 0);
  const timeOptionText = pluralize(numberOfOptions, 'time option', 'time options');

  return (
    <>
      <AvatarMain className="avatar-items-stack" user={owner.user} isOwner />
      <div className="flex flex-col items-center mt-4 availability-details-align-adj">
        <h2 className="font-medium my-0 text-xl availability-details-align-adj">
          {availability.title} 
        </h2>
        <div className="flex flex-row mt-3">
          <FromWhom className="from-icon-adj text-battleship-grey"/>
          <span className="text-base font-medium text-battleship-grey availability-details-align-adj">
            {getOwnerName(owner.user)}
          </span>
        </div>
        <div className="flex flex-row">
          <Clock className="clock-icon-adj text-battleship-grey"/>
          <span className="text-base font-medium text-battleship-grey availability-details-align-adj">
            {duration}
          </span>
        </div>
      </div>
      {availability.notes && (
        <div className="availability-notes">
          <p className="my-0 font-medium text-base">
            <span className="text-battleship-grey">{availability.notes}</span>
          </p>
        </div>
      )}
    </>
  );
};

export default EventDetails;
