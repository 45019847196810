import React from 'react';

import SVG, { svgProps } from './svg';

const FromWhom = ({
  className,
  height = '14',
  title = 'FromWhom',
  width = '14',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="currentColor" fillRule="evenodd">
      <g>
        <g>
            <path d="M12 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm.055 1.32c-.188 0-.347.064-.477.192-.13.127-.195.287-.195.48.004.163.051.295.142.398l.077.07.671.587.274.265-.774-.039h-1.671c-.204 0-.372.068-.504.203-.133.136-.2.31-.2.524 0 .208.067.381.2.52.106.11.235.176.386.198l.118.008h1.671l.758-.039-.258.258-.671.586c-.073.057-.128.129-.165.215-.036.086-.054.173-.054.262 0 .187.065.347.195.48s.29.197.477.192c.146-.005.275-.05.387-.138l.081-.073 1.836-1.946c.162-.161.243-.336.243-.523 0-.15-.052-.293-.155-.43l-.088-.101-1.836-1.946c-.13-.135-.286-.203-.468-.203zM6 8.501c.605 0 1.157.045 1.658.125-.726.932-1.158 2.103-1.158 3.376 0 .705.133 1.38.375 2H1.313C.588 14.002 0 13.498 0 12.876v-.614c0-1.382 1.57-3.76 6-3.76zM6 0c1.933 0 3.5 1.567 3.5 3.5S7.933 7 6 7 2.5 5.433 2.5 3.5 4.067 0 6 0z" transform="translate(-5819 -2018) translate(200 371) translate(2438 151) translate(1641 48) translate(0 1314) translate(0 134) translate(1540)"/>
        </g>
      </g>
    </g>
  </SVG>
);

FromWhom.propTypes = svgProps;

export default FromWhom;