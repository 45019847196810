import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';

import Button from '../button';

import BackArrowIcon from '../icons/back-arrow';
import ThumbsUpIcon from '../icons/thumbs-up';

import '../Availability/availability.css';

const ReplyConfirmation = ({ owner = {}, contactData, isEmail, onBack }) => {
  const { givenName = '' } = owner.user || {};
  const contactText = isEmail ? contactData : formatPhoneNumber(contactData);

  return (
  <>
    <div className="container" style={{maxHeight: 0}}>
      <div className="availability-header-label">
        <a href="https://www.weve.app" cursor="pointer" className="text-2xl font-medium text-green-weve"><span>weve.</span></a>
      </div>
    </div>
    <div className="container w-confirmation flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold text-center mb-4 mt-0">Thanks for Participating! 👍</h1>
      <p className="text-sm text-center">
        Stay tuned for updates. {givenName} will get back to you with the final details for the event at <strong>{contactText}</strong>.
      </p>
      <div className="flex justify-between w-confirmation-buttons">
        <Button className="flex-1 mr-1" appearance="secondary" size="circular" onClick={onBack}>
          <div className="flex items-center justify-center">
            <BackArrowIcon className="mr-2" height="12" width="12" />
            Back to Poll
          </div>
        </Button>
        <Button as="a" className="flex-1 ml-1" appearance="primary" size="circular" href="https://www.weve.app">
          <div className="flex items-center justify-center">
            <ThumbsUpIcon className="mr-2 mb-0-5" height="12" width="12" />
            Done
          </div>
        </Button>
      </div>
    </div>
    </>
  );
};

export default ReplyConfirmation;
