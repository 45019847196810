import React from 'react';

import './stickyFooter.css';

const StickyFooter = ({ children }) => {
  return (
    <div className="sticky-footer">
      {children}
    </div>
  );
}

export default StickyFooter;
