import axios from 'axios';

const baseUrl = process.env.GATSBY_API_BASE_URL;

export const getEvent = (eventId, userId) => {
  if (!eventId) {
    return Promise.reject('Missing event');
  }

  return axios.get(`${baseUrl}/eventDetails`, {
    params: {
      eventId,
      userId,
    }
  });
};

export const updateEventParticipant = (eventId, userId, fullname, status) => {
  const payload = {
    eventId,
    userId,
    fullname,
    status,
  };

  return axios.put(`${baseUrl}/updateParticipantStatus`, JSON.stringify(payload));
};