import React from 'react';

import SVG, { svgProps } from './svg';

const Phone = ({
  className,
  height = '16',
  title = 'Phone',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M15.086 10.4l-3.137-1.392c-.638-.282-1.386-.101-1.824.442l-.947 1.185c-1.535-.976-2.836-2.278-3.813-3.812l1.184-.946c.545-.438.727-1.187.444-1.826L5.6.914C5.294.226 4.536-.14 3.807.049L1.155.738c-.757.198-1.247.93-1.14 1.706 1.009 7.019 6.523 12.533 13.542 13.541.07.01.141.015.212.015.702 0 1.316-.475 1.493-1.154l.688-2.653c.192-.729-.174-1.489-.864-1.793z" transform="translate(-77 -287) translate(16 110) translate(0 60) translate(16 16) translate(0 88) translate(3 3) translate(42 9) translate(0 1)"/>
      </g>
    </g>
  </SVG>
);

Phone.propTypes = svgProps;

export default Phone;
