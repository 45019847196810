import React from "react";

import './wrapper.css';

const IconWrapper = ({
  badge,
  bg = '#ffffff',
  children,
  size,
}) => {
  return (
    <div className="icon-wrapper" style={{ backgroundColor: bg, height: size, width: size }}>
      {children}
      {badge && (
        <div className="wrapper-badge">
          {badge}
        </div>
      )}
    </div>
  );
};

export default IconWrapper;
