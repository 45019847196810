import React from "react";

import Button from '../button';
import Modal from '../modal';
import EventDetails from '../event-details';
import CalendarSheet from '../calendar-sheet';

import GoogleLogoIcon from '../icons/google-logo';
import AppleLogoIcon from '../icons/apple-logo';
import OutlookLogoIcon from '../icons/outlook-logo';
import YahooLogoIcon from '../icons/yahoo-logo';
// import WeveLogoIcon from '../icons/weve-logo';

import {
  generateGoogleURL,
  generateICS,
  generateYahooURL,
} from '../../utils/calendar';

const AddToCalendarModal = ({ event, isOpen, closeModal }) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Add to Your Calendar"
      onRequestClose={closeModal}
      header="Add to Your Calendar"
    >
      <div className="modal-body">
        <div className="flex justify-center mt-4">
          <CalendarSheet date={event.startDate} />
        </div>
        <EventDetails event={event} />
        <div className="add-to-calendar-buttons">
          <Button as="a" appearance="secondary" href={generateGoogleURL(event)} target="_blank" size="medium">
            <div className="flex flex-col items-center">
              <GoogleLogoIcon className="mb-1" />
              Google
            </div>
          </Button>
          <Button as="a" appearance="secondary" href={generateICS(event)} target="_blank" size="medium">
            <div className="flex flex-col items-center">
              <AppleLogoIcon className="mb-1" />
              Apple
            </div>
          </Button>
          <Button as="a" appearance="secondary" href={generateICS(event)} target="_blank" size="medium">
            <div className="flex flex-col items-center">
              <OutlookLogoIcon className="mb-1" />
              Outlook
            </div>
          </Button>
          <Button as="a" appearance="secondary" href={generateYahooURL(event)} target="_blank" size="medium">
            <div className="flex flex-col items-center">
              <YahooLogoIcon className="mb-1" />
              Yahoo
            </div>
          </Button>
        </div>
        {/* <Button className="mt-3 w-full" appearance="primary" size="large" onClick={() => console.log('TODO')}>
          <div className="flex flex-col items-center">
              <WeveLogoIcon className="mb-1" />
              Weve
            </div>
        </Button> */}
        <Button className="mt-3 w-full" appearance="secondary" size="large" onClick={closeModal}>
          <div className="flex justify-center">Skip</div>
        </Button>
      </div>
    </Modal>
  );
};

export default AddToCalendarModal;
