import React from "react";

import { getShortMonth, getDayOfMonth, getDayOfWeek } from '../../utils/datefns';

import './calendarSheet.css';

const CalendarSheet = ({ date = '' }) => {
  const month = getShortMonth(date);
  const day = getDayOfMonth(date);
  const dayOfWeek = getDayOfWeek(date);

  return (
    <div className="calendar-sheet">
      <span className="calendar-sheet-month">{month}</span>
      <span  className="calendar-sheet-day">{day}</span>
      <span  className="calendar-sheet-dayofweek">{dayOfWeek}</span>
    </div>
  );
};

export default CalendarSheet;
