import { formatForICS } from './datefns';

const formatLocation = (location, structuredLocation) => {
  return (
    location ||
    (structuredLocation &&
      structuredLocation.address &&
      structuredLocation.address.replace(/\n/g, ', ')) ||
    ''
  );
};

const generateGoogleURL = event => {
  const {
    isAllDay,
    description,
    endDate,
    location,
    structuredLocation,
    startDate,
    title,
  } = event;
  const start = formatForICS(startDate, isAllDay);
  const end = formatForICS(endDate, isAllDay);

  return encodeURI(
    [
      'https://calendar.google.com/calendar/render?action=TEMPLATE',
      `&text=${title || ''}`,
      `&dates=${start}/${end}`,
      `&details=${description || ''}`,
      `&location=${formatLocation(location, structuredLocation)}`,
    ].join('')
  );
};

const generateICS = event => {
  const {
    isAllDay,
    description,
    endDate,
    location,
    structuredLocation,
    startDate,
    title,
  } = event;
  const start = formatForICS(startDate, isAllDay);
  const end = formatForICS(endDate, isAllDay);

  const content = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${document.URL}`,
    'METHOD:PUBLISH',
    `DTSTART:${start}`,
    `DTEND:${end}`,
    `SUMMARY:${title || ''}`,
    `DESCRIPTION:${description || ''}`,
    `LOCATION:${formatLocation(location, structuredLocation)}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');

  return encodeURI(`data:text/calendar;charset=utf8,${content}`);
};

const generateYahooURL = event => {
  const {
    isAllDay,
    description,
    endDate,
    location,
    structuredLocation,
    startDate,
    title,
  } = event;
  const start = formatForICS(startDate, isAllDay);
  const end = formatForICS(endDate, isAllDay);

  return encodeURI(
    [
      'https://calendar.yahoo.com/?v=60&view=d&type=20',
      `&title=${title || ''}`,
      `&st=${start}`,
      `&et=${end}`,
      `&desc=${description || ''}`,
      `&in_loc=${formatLocation(location, structuredLocation)}`,
    ].join('')
  );
};

export { generateGoogleURL, generateICS, generateYahooURL };
