import React from 'react';

import SVG, { svgProps } from './svg';

const DeclineCross = ({
  className,
  height = '16',
  title = 'Decline',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
     <g fill="none" fill-rule="evenodd">
        <g fill="#FF577B">
            <g>
                <g>
                    <g>
                        <g>
                            <path d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm2.31 4.287l-.003.003-2.31 2.299L5.71 4.297c-.39-.391-1.024-.392-1.416 0-.362.36-.39.929-.084 1.322l.083.094h.001l2.292 2.288-2.292 2.287c-.391.39-.392 1.025-.001 1.416v.001c.391.391 1.026.391 1.417 0l2.288-2.292 2.287 2.291c.39.392 1.024.392 1.416.002.362-.362.39-.93.084-1.323l-.083-.094-.001-.001-2.292-2.287 2.297-2.292c.39-.39.392-1.02.006-1.412-.385-.39-1.012-.394-1.401-.01z" transform="translate(-62 -475) translate(0 154) translate(16 214) translate(0 99) translate(46 8)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SVG>
);

DeclineCross.propTypes = svgProps;

export default DeclineCross;
