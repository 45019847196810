import React from "react";

import Banner from './banner';
import Button from '../button';

import ThreeDayTimesCalendarIcon from '../icons/three-day-times-calendar';
import IconWrapper from '../icons/wrapper';
import WeveLogoIcon from '../icons/weve-logo';

import { checkForiOS } from '../../utils/os';

const appleStoreURL = process.env.GATSBY_APPLE_STORE_URL;

const GetWeveMultipleBanner = () => {
  const isiOS = checkForiOS();

  if (isiOS) {
    return (
      <Banner>
        <div className="flex items-center justify-between">
          <IconWrapper size="4.125rem"><ThreeDayTimesCalendarIcon /></IconWrapper>
          <div className="flex-1 flex flex-col items-start px-3 text-blackish text-left">
            <strong className="mb-1 text-sm">View with My Schedule</strong>
            <p className="leading-snug my-0 text-xs">See these options against your calendar with Weve.</p>
          </div>
          <Button as="a" size="small" href={appleStoreURL} rel="noreferrer">Get Weve</Button>
        </div>
      </Banner>
    );
  } else {
    return (
      <Banner>
        <div className="flex items-center justify-between">
          <IconWrapper size="4.125rem"><ThreeDayTimesCalendarIcon/></IconWrapper>
          <div className="flex-1 flex flex-col items-start pl-3 text-blackish text-left">
            <strong className="mb-1 text-sm">View these time options against your schedule</strong>
            <div className="my-0 text-xs get-the-app-button">
              <span className="text-xs px-2 font-medium">Download the app for free</span>
            </div>
          </div>
        </div>
      </Banner>
    )
  }
}

export default GetWeveMultipleBanner;
