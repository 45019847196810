import React from 'react';

import SVG, { svgProps } from './svg';

const Checkmark = ({
  className,
  height = '16',
  title = 'Checkmark',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm3.705 5.16c-.352-.352-.922-.352-1.273 0L6.977 8.613l-1.409-1.41c-.351-.35-.921-.35-1.273 0l-.159.16c-.351.351-.351.92 0 1.272l2.205 2.205c.351.351.921.351 1.273 0l4.25-4.25c.351-.352.351-.921 0-1.273z" transform="translate(-297 -475) translate(0 154) translate(16 214) translate(0 99) translate(235) translate(46 8)"
    />
  </SVG>
);

Checkmark.propTypes = svgProps;

export default Checkmark;
