import React from 'react';

import SVG, { svgProps } from './svg';

const Cross = ({
  className,
  height = '28',
  title = 'Location Pin',
  width = '28',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 28 28"
  >
    <path fill="currentColor" fillRule="nonzero" d="M14 2.5c-4.4 0-9 3.4-9 9 0 5.3 8 13.4 8.3 13.7.2.2.4.3.7.3.3 0 .5-.1.7-.3.3-.3 8.3-8.4 8.3-13.7 0-5.6-4.6-9-9-9zm0 12c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z"/>
  </SVG>
);

Cross.propTypes = svgProps;

export default Cross;
