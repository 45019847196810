import React from 'react';

import SVG, { svgProps } from './svg';

const ThumbsUp = ({
  className,
  height = '12',
  title = 'Thumbs Up',
  width = '12',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 13 13"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <g>
          <path d="M9.96 4.5c.6 0 1.198.225 1.572.75.374.375.524.975.45 1.575l-.6 3.45C11.233 11.25 10.41 12 9.438 12H3V6C3.075 6 4.796.375 4.796.375c0-.225.225-.375.45-.375.897 0 1.496.375 1.496 1.5v3H9.96zM.75 6c.414 0 .75.336.75.75v4.5c0 .414-.336.75-.75.75S0 11.664 0 11.25v-4.5C0 6.336.336 6 .75 6z" transform="translate(-244 -499) translate(0 154) translate(27 203.5) translate(.5 128) translate(164.5) translate(52.75 12) translate(0 2)"/>
        </g>
      </g>
    </g>
  </SVG>
);

ThumbsUp.propTypes = svgProps;

export default ThumbsUp;
