import React, { memo } from 'react';

import SVG, { svgProps } from './svg';

const WeveLoader = ({
  className,
  height = '44',
  fill = 'currentColor',
  title = 'Weve',
  width = '44',
  ...props
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 49 37"
    {...props}
  >
    <g id="Page-1" stroke={fill} strokeWidth="3" fill="none" fillRule="evenodd">
      <g id="weve" transform="translate(1.000000, 1.000000)">
        <path
          d="M19.09,2.28420231 L13.771,16.5792023 C16.8526583,25.2899807 18.3216583,29.4486473 18.178,29.0552023 C16.6111807,24.7640662 9.95423869,6.33824661 8.30968755,2.28420231 C7.1854688,0.0223898145 4.52279866,-0.316171217 3.3117188,0.226202315 C1.1579688,1.19074398 0.178645881,2.76801481 0.373750048,4.95801481 L9.98273457,30.8362185 C10.5443288,32.1806452 11.8122926,32.8528586 13.786626,32.8528586 C15.7609593,32.8528586 17.2247507,31.5869731 18.178,29.0552023 L27.067,5.17920231 C27.552,3.86320231 26.724,1.05320231 24.357,0.226202315 C21.991,-0.599797685 19.562,1.01020231 19.09,2.28420231 Z"
          id="Path"
          transform="translate(13.774707, 16.426429) scale(-1, 1) translate(-13.774707, -16.426429) "
        />
        <path
          d="M37.5910903,2.28440463 L32.2720903,16.5794046 C35.3537486,25.290183 36.8227486,29.4488496 36.6790903,29.0554046 C35.112271,24.7642686 28.455329,6.33844892 26.8107778,2.28440463 C25.6865591,0.0225921291 23.023889,-0.315968902 21.8128091,0.226404629 C19.6590591,1.1909463 18.6797362,2.76821713 18.8748403,4.95821713 L28.4838249,30.8364209 C29.0454191,32.1808475 30.3133829,32.8530609 32.2877162,32.8530609 C34.2620496,32.8530609 35.7258409,31.5871755 36.6790903,29.0554046 L45.5680903,5.17940463 C46.0530903,3.86340463 45.2250903,1.05340463 42.8580903,0.226404629 C40.4920903,-0.599595371 38.0630903,1.01040463 37.5910903,2.28440463 Z"
          id="Path"
        />
      </g>
    </g>
  </SVG>
);

WeveLoader.propTypes = svgProps;

export default memo(WeveLoader);
