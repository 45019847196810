import React from 'react';

import SVG, { svgProps } from './svg';

const Question = ({
  className,
  height = '17',
  title = 'Maybe',
  width = '17',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 17 16"
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1.5-4.6c-.5.3-.5.4-.5.6 0 .552-.448 1-1 1s-1-.448-1-1c0-1.3.8-1.9 1.4-2.3.5-.3.6-.4.6-.7 0-.6-.4-1-1-1-.4 0-.7.2-.9.5l-.021.038c-.258.464-.844.632-1.308.374L5.75 5.9l-.009-.005c-.468-.275-.63-.873-.367-1.348L5.4 4.5C5.9 3.6 6.9 3 8 3c1.7 0 3 1.3 3 3 0 1.4-.9 2-1.5 2.4z" transform="translate(-179 -475) translate(0 154) translate(16 214) translate(0 99) translate(117.5) translate(46 8)"
    />
  </SVG>
);

Question.propTypes = svgProps;

export default Question;
