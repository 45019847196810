import { format, formatDistanceStrict, isSameDay, isBefore as isDateBefore, isValid, parseJSON } from 'date-fns';

export function formatEventDate(start, end, isAllDay) {
  const startDate = parseJSON(start);
  const endDate = parseJSON(end);

  if (isValid(startDate) && isValid(endDate)) {
    const isTheSameDay = isSameDay(startDate, endDate);

    if (isAllDay && isTheSameDay) {
      return [formatDate(startDate)];
    }

    if (isAllDay) {
      return [`${formatDate(startDate)} - ${formatDate(endDate)}`];
    }

    if (isTheSameDay) {
      return [`${formatDate(startDate)}`, `${formatTime(startDate)} - ${formatTime(endDate)}`];
    }

    return [`${formatDate(startDate)}, ${formatTime(startDate)}`, `${formatDate(endDate)}, ${formatTime(endDate)}`];
  }
  
  return [];
}

export function formatEventForList(start, end, isAllDay) {
  const startDate = parseJSON(start);
  const endDate = parseJSON(end);

  if (isValid(startDate) && isValid(endDate)) {
    const isTheSameDay = isSameDay(startDate, endDate);

    if (isAllDay && isTheSameDay) {
      return formatDate(startDate);
    }

    if (isAllDay) {
      return `${formatDate(startDate)} - ${formatDate(endDate)} (${getDuration(start, end)})`;
    }

    if (isTheSameDay) {
      return `${formatTime(startDate)} - ${formatTime(endDate)}`;
    }

    return `${formatDate(startDate)}, ${formatTime(startDate)} - ${formatDate(endDate)}, ${formatTime(endDate)}`;
  }
  
  return '';
}

export function isTheSameDay(start, end) {
  const startDate = parseJSON(start);
  const endDate = parseJSON(end);

  if (isValid(startDate) && isValid(endDate)) {
    return isSameDay(startDate, endDate);
  }

  return false;
}

export function isBefore(date, dateToCompare = new Date()) {
  return isDateBefore(date, dateToCompare);
}

export function formatDate(date, pattern = 'eeee, MMMM d') {
  const parsedDate = parseJSON(date);

  if (isValid(parsedDate)) {
    return format(parsedDate, pattern);
  }

  return '';
}

export function formatTime(date) {
  const parsedDate = parseJSON(date);

  if (isValid(parsedDate)) {
    return format(parsedDate, 'p')
  }

  return '';
}

export function formatToDateString(date) {
  return format(date, 'yyyy-MM-dd');
}

export function getShortMonth(date) {
  const parsedDate = parseJSON(date);

  if (isValid(parsedDate)) {
    return format(parsedDate, 'MMM');
  }

  return '';
}

export function getDayOfMonth(date) {
  const parsedDate = parseJSON(date);

  if (isValid(parsedDate)) {
    return format(parsedDate, 'd');
  }

  return '';
}

export function getDayOfWeek(date) {
  const parsedDate = parseJSON(date);

  if (isValid(parsedDate)) {
    return format(parsedDate, 'EEE');
  }

  return '';
}

export function parseDateString(dateString) {
  return parseJSON(dateString);
}

export function getDuration(start, end) {
  const startDate = parseJSON(start);
  const endDate = parseJSON(end);

  if (isValid(startDate) && isValid(endDate)) {
    return formatDistanceStrict(startDate, endDate);
  }

  return '';
}

export const formatForICS = (date, isAllDay) => {
  const dateString = parseJSON(date).toISOString();

  return isAllDay ? dateString.split('T')[0] : dateString.replaceAll(/(-|:|(\.000))/g, '');
};
