import React from "react";

import IconWrapper from '../icons/wrapper';
import CrownIcon from '../icons/crown';
import CheckmarkIcon from '../icons/checkmark';
import ClockIcon from '../icons/clock';
import DeclineCrossIcon from '../icons/decline-cross';
import QuestionMarkIcon from '../icons/question-mark';

import './avatar-who.css';

const AvatarWho = ({
  isOwner = false,
  response = '',
  size = '3.5rem',
  initialsSize = '1.25rem',
  showName = true,
  user = {},
}) => {
  const { givenName = '', familyName = '', picture } = user;
  const initials = `${givenName.charAt(0)}${familyName.charAt(0)}`;
  const badgeBg = isOwner ? '#00cc96' : '';

  return (
    <div className="flex flex-col items-center overflow-hidden" title={givenName}>
      <IconWrapper
        size={size}
        badge={(isOwner || response) && (
          <IconWrapper bg={badgeBg} size="1.25rem">
            {isOwner && <CrownIcon className="text-white" />}
            {response === 'Accepted' && <CheckmarkIcon className="text-green-weve" height="1.25rem" width="1.25rem" />}
            {response === 'Tentative' && <QuestionMarkIcon className="text-greyish" height="1.25rem" width="1.25rem" />}
            {response === 'Rejected' && <DeclineCrossIcon className="text-red" height="1.25rem" width="1.25rem" />}
            {response === 'Sent' && <ClockIcon className="text-greyish" />}
          </IconWrapper>
        )}
      >
        {picture ? (
          <img className="avatar" src={picture} />
        ) : (
          <span className="avatar-initials" style={{ fontSize: initialsSize }}>{initials}</span>
        )}
      </IconWrapper>
      {showName && (<span className="avatar-name truncate" title={givenName}>{givenName}</span>)}
    </div>
  );
};

export default AvatarWho;
