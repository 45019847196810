import React from "react";

import './tabs.css';

const Tabs = ({ children, activeTab, headers = [], onChangeTab }) => {
  return (
    <div className="tabs">
      <ul className="tab-header">
        {headers.map((item) => (
          <li key={item}>
            <button
              className={`tab-button ${item === activeTab ? 'active' : ''}`}
              onClick={() => onChangeTab(item)}
            >
              {item}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {React.Children.map(children, (child) => {
          if (activeTab !== child.props.name) return;
          return child;
        })}
      </div>
    </div>
  );
};

export default Tabs;
