import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

import AvailabilityFooter from './availability-footer';

import CalendarForwardIcon from '../icons/calendar-forward';

import AvailabilityDetails from '../availability-details';
import CardTimeZone from "../card/card-time-zone";
import Calendar from '../calendar';
import GetWeveMultipleBanner from '../banner/get-weve-multiple';
import Loader from '../loader';
import ReplyConfirmation from '../reply-confirmation';
import SEO from '../seo';

import AvailabilityPickerModal from '../availability-picker-modal';
import EnterYourInfoModal from '../enter-your-info-modal';

import { getAvailability, updateAvailability } from '../../api/availability';
import { groupOptionsByDate } from "../../utils/availability";

import './availability.css';
import '../icons/wrapper.css';
import GetAccessModal from "../get-access-modal";

const appleStoreURL = process.env.GATSBY_APPLE_STORE_URL;

const Availability = () => {
  
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [availability, setAvailability] = useState();
  const [selectedDay, setSelectedDay] = useState({});
  const [isAvailabilityPickerModalOpen, setIsAvailabilityPickerModalOpen] = useState(false);
  const [isGetAccessModalOpen, setIsGetAccessModalOpen] = useState(false);
  const [isEnterYourInfoModalOpen, setIsEnterYourInfoModalOpen] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [contactName, setContactName] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [contactData, setContactData] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [owner, setOwner] = useState();

  useEffect(() => {
    const { id } = parse(location.search);
    getAvailability(id)
      .then((response) => {
        setIsLoading(false);
        setAvailability({
          ...response.data,
          options: groupOptionsByDate(response.data.options),
        });
        setOwner(response.data.participants.find((p) => p.isOwner));
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function openEnterYourInfoModal() {
    closeAvailabilityPickerModal();
    setIsEnterYourInfoModalOpen(true);
  }

  function closeEnterYourInfoModal() {
    setIsEnterYourInfoModalOpen(false);
  }

  function closeAvailabilityPickerModal() {
    setIsAvailabilityPickerModalOpen(false);
  }

  function hideConfirmationComponent() {
    setSubmitted(false);
  }

  function onSelectEvent(event) {
    setSelectedDay(event);
    setIsAvailabilityPickerModalOpen(true);
  }

  function onChangeDate(direction) {
    const nextIndex = selectedDay.index + direction;

    if (nextIndex > -1 && nextIndex < availability.options.length) {
      setSelectedDay(availability.options[nextIndex]);
    }
  }

  function onSelectTime(id) {
    if (selectedTimes.indexOf(id) > -1) {
      setSelectedTimes((times) => times.filter((time) => time !== id));
    } else {
      setSelectedTimes((times) => [...times, id]);
    }
  }

  function openGetAccessModal() {
    setIsGetAccessModalOpen(true);
  }
  
  function closeGetAccessModal() {
    setIsGetAccessModalOpen(false);
  }

  function submitAvailability() {
    const userId = isEmail ? contactData : encodeURIComponent(contactData);
    setIsLoading(true);

    updateAvailability(availability.pollId, userId, contactName, selectedTimes)
      .then(({ data }) => {
        setIsLoading(false);
        closeEnterYourInfoModal();
        setSubmitted(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      <SEO
        title="Availability"
        image="/images/weve-poll-image-square.png"
        imageHeight={606}
        imageWidth={606}
        ogTitle="Weve Availability - Select One or More Times"
      />
      {submitted ? (
        <ReplyConfirmation
          owner={owner}
          contactData={contactData}
          isEmail={isEmail}
          onBack={hideConfirmationComponent}
        />
      ) : (
        <>
          <div className="container">
            <div className="availability-header-label">
              <a href="https://www.weve.app" cursor="pointer" className="text-2xl font-medium text-green-weve"><span>weve.</span></a>
              <span className="text-2xl font-light text-greyish-2">availability</span>
            </div>
          </div>
          <div className="container max-w-2xl py-4">
              <div className="availability-preface-wrapper">
                <div className="banner-wrapper-top">
                  <GetWeveMultipleBanner />  
                </div> 
                <div className="availability-preface-info">
                  {availability && (
                    <>
                      <AvailabilityDetails availability={availability} type="Shared Availability" />                      
                    </>
                  )}
                 <div className="availability-avatar-badge-container" >
                    <div className="availability-avatar-badge-wrapper">
                      <div className="big-wrapper-badge-green-shadow">
                        <CalendarForwardIcon />
                      </div>
                    </div>    
                  </div>
                </div>
                <>
                  <a 
                    className="banner-wrapper-bottom"
                    tabIndex="0"
                    onClick={openGetAccessModal}
                  >
                  <GetWeveMultipleBanner />  
                  </a>
                  <GetAccessModal
                  isOpen={isGetAccessModalOpen}
                  closeModal={closeGetAccessModal}
                  />
                </>
              </div>
              {availability && (
                <>
                  <div className="availability-card-wrapper">
                    <CardTimeZone title="Choose Dates and Times" bodyPadding={false}>
                      <Calendar
                      events={availability.options}
                      selectedTimes={selectedTimes}
                      onSelectEvent={onSelectEvent}
                      />
                    </CardTimeZone>
                  </div>
                </>
              )}
            <div className="picker-modal-placeholder"></div>
          </div>
          <AvailabilityPickerModal
            isOpen={isAvailabilityPickerModalOpen}
            closeModal={closeAvailabilityPickerModal}
            option={selectedDay}
            selectedTimes={selectedTimes}
            onChangeDate={onChangeDate}
            onSelectTime={onSelectTime}
            onSubmit={openEnterYourInfoModal}
          />
          <EnterYourInfoModal
            isOpen={isEnterYourInfoModalOpen}
            closeModal={closeEnterYourInfoModal}
            contactName={contactName}
            setContactName={setContactName}
            isEmail={isEmail}
            setIsEmail={setIsEmail}
            contactData={contactData}
            setContactData={setContactData}
            onSubmit={submitAvailability}
            submitButtonText={`Submit Times (${selectedTimes.length})`}
          />
          <AvailabilityFooter selectedTimes={selectedTimes.length} onSubmit={openEnterYourInfoModal} />
        </>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Availability;
