import React from 'react';

import SVG, { svgProps } from './svg';

const MinimizeArrows = ({
  className,
  height = '12',
  title = 'Minimize',
  width = '12',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M5.014 5.75l.131.008c.53.062.96.455 1.075.978l.023.133L6.25 7v3.76l-.008.14C6.169 11.527 5.636 12 5 12c-.594 0-1.097-.413-1.222-.99l-.02-.13-.008-.13-.001-2.5H1.24l-.14-.008C.473 8.169 0 7.636 0 7c0-.594.413-1.097.99-1.222l.13-.02.13-.008h3.764zM7 0c.594 0 1.097.413 1.222.99l.02.13.008.13-.001 2.5h2.511l.14.008c.627.073 1.1.606 1.1 1.242 0 .594-.413 1.097-.99 1.222l-.13.02-.13.008H6.986l-.131-.008c-.53-.062-.96-.455-1.075-.978l-.023-.133L5.75 5V1.24l.008-.14C5.831.473 6.364 0 7 0z" transform="translate(-24 -134) translate(0 94) translate(0 16) translate(8 8) translate(8 8) translate(8 8)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SVG>
);

MinimizeArrows.propTypes = svgProps;

export default MinimizeArrows;
