import React, { useState } from "react";

import AvailabilityFooter from '../Availability/availability-footer';
import AvailabilityPickerToolbar from './availability-picker-toolbar';
import Button from '../button';
import Modal from '../modal';

import CheckmarkIcon from '../icons/checkmark';
import ExpandArrowsIcon from '../icons/expand-arrows';
import MinimizeArrowsIcon from '../icons/minimize-arrows';

import IconWrapperNoshadow from '../icons/wrapper-noshadow';

import { formatDate, formatTime } from '../../utils/datefns';

import '../Availability/availability.css';
import './availability-picker-modal.css';

const AvailabilityPickerModal = ({
  isOpen,
  closeModal,
  option = {},
  selectedTimes = [],
  onChangeDate,
  onSelectTime,
  onSubmit,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { start, events = [] } = option;
  const header = isExpanded ? 'Select Preferred Time(s)' : formatDate(start, 'EEE, MMMM d');

  function toggleExpand() {
    setIsExpanded((expanded) => !expanded);
  }

  return (
    <Modal
      isOpen={isOpen}
      modalClassName={`availability-picker-modal ${isExpanded ? 'expanded' : ''}`}
      overlayClassName="availability-picker-overlay"
      contentLabel="Select Availability"
      onRequestClose={closeModal}
      leftButton={
        <button className="modal-header-button" onClick={toggleExpand}>
          <IconWrapperNoshadow bg="#ebedee" size="1.75rem">
            {isExpanded ? (
              <MinimizeArrowsIcon className="text-blackish" />
            ) : (
              <ExpandArrowsIcon className="text-blackish" />
            )}
          </IconWrapperNoshadow>
        </button>
      }
      header={<span className="font-bold">{header}</span>}
      showOverlayBg={isExpanded}
    >
      {isExpanded && (
        <AvailabilityPickerToolbar date={start} onNavigate={onChangeDate} />
      )}
          <div className="modal-body">
            {events.map((event) => (
              <Button
                key={event.id}
                className={`timeslot-button ${selectedTimes.includes(event.id) ? 'active' : ''}`}
                appearance="secondary"
                onClick={() => onSelectTime(event.id)}
              >
                <div className="timeslot-button-content">
                  <span>{formatTime(event.start)} - {formatTime(event.end)}</span>
                  {selectedTimes.includes(event.id) && (
                    <CheckmarkIcon
                      className="timeslot-checkmark"
                      height="1.25rem"
                      width="1.25rem"
                    />
                  )}
                </div>
              </Button>
            ))}
          </div>
      <AvailabilityFooter selectedTimes={selectedTimes.length} onSubmit={onSubmit} />
    </Modal>
  );
};

export default AvailabilityPickerModal;
