import React from "react";

import './input.css';

const Input = React.forwardRef(({
  containerClass = '',
  inputClass = '',
  error = '',
  helpText = '',
  id = '',
  label = '',
  size = 'medium',
  type = 'text',
  value,
  ...props
}, ref) => {
  const containerClasses = `input-container ${containerClass}`;
  const inputClasses = `input ${inputClass}${error ? ' error' : ''}`;
  const labelClasses = `input-label${value ? ' has-value' : ''}`;

  return (
    <div className={containerClasses}>
      <input ref={ref} className={inputClasses} type={type} id={id} value={value} {...props} />
      <label className={labelClasses} htmlFor={id}>{label}</label>
      {error ? (
        <span className="input-error-text">{error}</span>
      ) : (
        <span className="input-help-text">{helpText}</span>
      )}
    </div>
  );
});

export default Input;
