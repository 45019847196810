import React from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

import Toolbar from './toolbar';
import MonthDateHeader from './monthDateHeader';
import MonthEvent from './monthEvent';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const formats = {
  dateFormat: 'd',
  weekdayFormat: 'EEE',
};

const Calendar = ({ events = [], selectedTimes = [], onSelectEvent }) => {
  return (
    <div className="weve-calendar">
      <BigCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={{
          month: true,
          week: false,
          day: false,
          agenda: false,
        }}
        drilldownView={null}
        formats={formats}
        components={{
          toolbar: Toolbar,
          month: {
            dateHeader: (props) => <MonthDateHeader {...props} events={events} selectedTimes={selectedTimes} />,
            event: (props) => <MonthEvent {...props} selectedTimes={selectedTimes} />,
          },
        }}
        style={{ height: 400 }}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
};

export default Calendar;
