import React from "react";
import { Router } from "@reach/router";

import Layout from "../components/layout";
import Availability from "../components/Availability";
import Poll from "../components/Poll";
import Rsvp from "../components/Rsvp";

const App = () => {
  return (
    <Layout>
      <Router basepath="/app">
        <Availability path="/availability" />
        <Poll path="/poll" />
        <Rsvp path="/rsvp" />
      </Router>
    </Layout>
  );
};

export default App;
