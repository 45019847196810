import React from 'react';

import SVG, { svgProps } from './svg';

const ThreeDayTimesCalendar = ({
  className,
  height = '33',
  title = 'ThreeDayTimesCalendar',
  width = '33',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 32 33"
  >
    <g fill="none">
      <path transform="translate(0 .5)" d="m27 0c2.761 0 5 2.239 5 5v22c0 2.761-2.239 5-5 5h-22c-2.761 0-5-2.239-5-5v-22c0-2.761 2.239-5 5-5zm1 8h-24c-1.105 0-2 0.895-2 2v16c0 2.21 1.79 4 4 4h20c2.21 0 4-1.79 4-4v-16c0-1.105-0.895-2-2-2z" fill="#798c92"/>
    </g>
    <path x="4" y="16.615" width="7" height="5.5" d="m5.5 16.615h4a1.5 1.5 45 0 1 1.5 1.5v2.5a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-2.5a1.5 1.5 135 0 1 1.5-1.5z" fill="#cdd4d6" fill-rule="evenodd" stroke-width="1.1726"/>
    <g fill="#00cc96" fill-rule="evenodd">
      <path x="4" y="10.99" width="7" height="4.25" d="m5.5 10.99h4a1.5 1.5 45 0 1 1.5 1.5v1.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-1.25a1.5 1.5 135 0 1 1.5-1.5z" stroke-width="1.0308"/>
      <path x="4" y="23.49" width="7" height="4.5" d="m5.5 23.49h4a1.5 1.5 45 0 1 1.5 1.5v1.5a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-1.5a1.5 1.5 135 0 1 1.5-1.5z" stroke-width="1.0607"/>
      <path x="12.5" y="17.865" width="7" height="3.25" d="m14 17.865h4a1.5 1.5 45 0 1 1.5 1.5v0.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-0.25a1.5 1.5 135 0 1 1.5-1.5z" stroke-width=".90139"/>
    </g>
    <g fill-rule="evenodd">
      <path x="12.5" y="10.99" width="7" height="5.5" d="m14 10.99h4a1.5 1.5 45 0 1 1.5 1.5v2.5a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-2.5a1.5 1.5 135 0 1 1.5-1.5z" fill="#cdd4d6" stroke-width="1.1726"/>
      <path x="12.5" y="22.49" width="7" height="5.5" d="m14 22.49h4a1.5 1.5 45 0 1 1.5 1.5v2.5a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-2.5a1.5 1.5 135 0 1 1.5-1.5z" fill="#cdd4d6" stroke-width="1.1726"/>
      <path x="21" y="20.156668" width="7" height="3.25" d="m22.5 20.157h4a1.5 1.5 45 0 1 1.5 1.5v0.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-0.25a1.5 1.5 135 0 1 1.5-1.5z" fill="#00cc96" stroke-width=".90139"/>
    </g>
    <g fill="#cdd4d6" fill-rule="evenodd" stroke-width=".90139">
      <path x="21" y="10.99" width="7" height="3.25" d="m22.5 10.99h4a1.5 1.5 45 0 1 1.5 1.5v0.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-0.25a1.5 1.5 135 0 1 1.5-1.5z"/>
      <path x="21" y="15.573333" width="7" height="3.25" d="m22.5 15.573h4a1.5 1.5 45 0 1 1.5 1.5v0.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-0.25a1.5 1.5 135 0 1 1.5-1.5z"/>
      <path x="21" y="24.740002" width="7" height="3.25" d="m22.5 24.74h4a1.5 1.5 45 0 1 1.5 1.5v0.25a1.5 1.5 135 0 1-1.5 1.5h-4a1.5 1.5 45 0 1-1.5-1.5v-0.25a1.5 1.5 135 0 1 1.5-1.5z"/>
    </g>

  </SVG>
);

ThreeDayTimesCalendar.propTypes = svgProps;

export default ThreeDayTimesCalendar;
