import { compareAsc } from 'date-fns';

import { parseDateString, formatToDateString, isBefore } from "./datefns";
import { pluralize } from "./wording";

export function groupOptionsByDate(options = []) {
  const groupedOptions = options.reduce((groups, option) => {
    const start = parseDateString(option.startTime);

    if (isBefore(start)) {
      return groups;
    }

    const nextGroups = { ...groups };
    const groupId = formatToDateString(start);
    const nextOption = {
      title: option.title,
      id: option.id,
      isAllDay: option.isAllDay,
      responses: option.responses,
      start,
      end: parseDateString(option.endTime),
    };

    nextGroups[groupId] = [...(nextGroups[groupId] || []), nextOption];

    return nextGroups;
  }, {});

  return Object.keys(groupedOptions)
    .map((groupId) => {
      return {
        title: pluralize(groupedOptions[groupId].length, 'time', 'times'),
        start: groupedOptions[groupId][0].start,
        end: groupedOptions[groupId][0].end,
        allDay: groupedOptions[groupId][0].isAllDay,
        events: groupedOptions[groupId].sort((a, b) => compareAsc(a.start, b.start)),
      };
    })
    .sort((a, b) => compareAsc(a.start, b.start))
    .map((option, index) => ({
      ...option,
      index,
    }));
}