import React from 'react';

import './toggle.css';

const Toggle = ({
  checked,
  id = '',
  falseLabel = '',
  trueLabel = '',
  ...props
}) => {
  return (
    <div className="toggle-container">
      <input
        className="toggle-checkbox"
        id={id}
        type="checkbox"
        checked={checked}
        {...props}
      />
      <label
        className="toggle-label"
        htmlFor={id}
      >
        <span className="toggle-button" />
        <span className={`toggle-label-text${checked ? '' : ' active'}`}>{falseLabel}</span>
        <span className={`toggle-label-text${checked ? ' active' : ''}`}>{trueLabel}</span>
      </label>
    </div>
  );
};

export default Toggle;