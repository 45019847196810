import React from 'react';

import SVG, { svgProps } from './svg';

const ForwardArrow = ({
  className,
  height = '16',
  title = 'Next',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M8.545 1.96c-.392.488-.372 1.209.08 1.66l3.136 3.132-10.512.001c-.69 0-1.25.56-1.25 1.25v.128c.064.63.603 1.122 1.25 1.122h10.512l-3.126 3.125c-.484.484-.484 1.276 0 1.76s1.276.477 1.76-.007l5.24-5.244c.488-.488.488-1.28 0-1.768l-5.25-5.258c-.484-.485-1.276-.475-1.76.01l-.08.09z" transform="translate(-325 -520) translate(0 154) translate(16 302) translate(0 48) translate(299 6) translate(10 10)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SVG>
);

ForwardArrow.propTypes = svgProps;

export default ForwardArrow;
