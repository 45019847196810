import React from 'react';

import SVG, { svgProps } from './svg';

const Clock = ({
  className,
  height = '12',
  title = 'Pending',
  width = '12',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <path d="M6 0C2.7 0 0 2.7 0 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm3 6.75H5.25V3h1.5v2.25H9v1.5z" transform="translate(-4 -4) translate(4 4)"/>
        </g>
      </g>
    </g>
  </SVG>
);

Clock.propTypes = svgProps;

export default Clock;
