import React from "react";

import './card.css';

import TimeZoneGlobeIcon from '../icons/time-zone-globe';

const CardTimeZone = ({
  bodyPadding = true,
  children,
  title = ''
}) => {
  return (
    <div className="card">
      <div className="card-header">{title}
        <div className="flex justify-center">
          <div className="flex flex-row">
          <TimeZoneGlobeIcon className="globe-icon-adj text-battleship-grey"></TimeZoneGlobeIcon><p className="my-0 font-bold text-2xs text-center text-battleship-grey" >
            <span>Time Zone: Local</span></p>
          </div>
        </div>
      </div>
      <div className={`card-body ${bodyPadding ? 'padded' : ''}`}>{children}</div>
    </div>
  );
};

export default CardTimeZone;