import axios from 'axios';

const baseUrl = process.env.GATSBY_API_BASE_URL;

export const getAvailability = (id) => {
  if (!id) {
    return Promise.reject('Missing id');
  }

  return axios.get(`${baseUrl}/pollDetails`, {
    params: {
      pollId: encodeURIComponent(id),
    }
  });
};

export const updateAvailability = (pollId, userId, fullname, options) => {
  const payload = {
    pollId,
    userId,
    fullname,
    options: options.map((id) => ({ id, preference: 'YES' })),
  };

  return axios.put(`${baseUrl}/pollResponse`, JSON.stringify(payload));
};
