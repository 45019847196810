import React from 'react';

import SVG, { svgProps } from './svg';

const BackArrow = ({
  className,
  height = '16',
  title = 'Previous',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path d="M7.455 1.96c.392.488.372 1.209-.08 1.66L4.239 6.753l10.512.001c.69 0 1.25.56 1.25 1.25v.128c-.064.63-.603 1.122-1.25 1.122H4.239l3.126 3.125c.484.484.484 1.276 0 1.76s-1.276.477-1.76-.007L.365 8.887c-.488-.488-.488-1.28 0-1.768l5.25-5.258c.484-.485 1.276-.475 1.76.01l.08.09z" transform="translate(-34 -520) translate(0 154) translate(16 302) translate(0 48) translate(8 6) translate(10 10)"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SVG>
);

BackArrow.propTypes = svgProps;

export default BackArrow;
