import React from 'react';

import SVG, { svgProps } from './svg';

const CalendarSingleHighlight = ({
  className,
  height = '32',
  title = 'CalendarSingleHighlight',
  width = '32',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path fill="#798C92" fillRule="nonzero" d="M27 0c2.761 0 5 2.239 5 5v22c0 2.761-2.239 5-5 5H5c-2.761 0-5-2.239-5-5V5c0-2.761 2.239-5 5-5h22zm1 8H4c-1.105 0-2 .895-2 2v16c0 2.21 1.79 4 4 4h20c2.21 0 4-1.79 4-4V10c0-1.105-.895-2-2-2zm-7 17.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm20-5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm20-5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-10 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm20-5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-5 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" transform="translate(-45 -195) translate(0 154) translate(16 16) translate(12 8.5) translate(17 17)"/>
                <path fill="#00CC96" d="M16 14.25c1.243 0 2.25 1.007 2.25 2.25s-1.007 2.25-2.25 2.25-2.25-1.007-2.25-2.25 1.007-2.25 2.25-2.25z" transform="translate(-45 -195) translate(0 154) translate(16 16) translate(12 8.5) translate(17 17)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

CalendarSingleHighlight.propTypes = svgProps;

export default CalendarSingleHighlight;
