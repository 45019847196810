import React from 'react';

import WeveLoader from '../icons/weve-loader';

import './loader.css';

const Loader = () => {
  return (

      <div className="loader-overlay">
        <WeveLoader
          className="loader"
          fill="#00CC96"
          height="64"
          width="64"
        />
      </div>
  );
};

export default Loader;
