import React from "react";
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/input';

import Button from '../button';
import Input from '../input';
import Modal from '../modal';
import Toggle from '../toggle';
import EmailIcon from '../icons/email';
import PhoneIcon from '../icons/phone';

import 'react-phone-number-input/style.css';

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const EnterYourInfoModal = ({
  isOpen,
  closeModal,
  contactName,
  setContactName,
  isEmail,
  setIsEmail,
  contactData,
  setContactData,
  onSubmit,
  submitButtonText = 'Submit',
}) => {
  const hasErrors = !contactName || (isEmail && !emailIsValid(contactData) || (!isEmail && !isPossiblePhoneNumber(contactData)));
  function handleContactMethodToggle() {
    setIsEmail(!isEmail);
    setContactData('');
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Enter Your Info"
      onRequestClose={closeModal}
      header="Enter Your Info"
    >
      <div className="modal-body">
        <Input
          containerClass="mb-4"
          id="name"
          label="Name"
          helpText="This is how you’ll be identified in the event details."
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
        />
        <Toggle
          id="contactMethod"
          falseLabel={(
            <>
              <PhoneIcon />
              <span className="pl-2">Mobile</span>
            </>
          )}
          trueLabel={(
            <>
              <EmailIcon height="1rem" width="1rem" />
              <span className="pl-2">Email</span>
            </>
          )}
          checked={isEmail}
          onChange={handleContactMethodToggle}
        />
        {isEmail ? (
          <Input
            id="email"
            label="Email Address"
            helpText="This is how you’ll receive final details about the event."
            type="email"
            value={contactData}
            onChange={(e) => setContactData(e.target.value)}
            error={contactData ? (emailIsValid(contactData) ? '' : '') : ''}
          />
        ) : (
          <PhoneInput
            id="mobileNumber"
            defaultCountry="US"
            label="Mobile Number"
            helpText="This is how you’ll receive final details about the event."
            inputComponent={Input}
            value={contactData}
            onChange={setContactData}
            international
            error={contactData ? (isPossiblePhoneNumber(contactData) ? '' : '') : ''}
            /* Nate: Removed 'Invalid email address/phone number' after "? '' : '_goes here_')" as form won't let you submit 
            anyway until this is valid and will show an error upon first character which is annoying 
            and shouldn't be shown unless a user attempts to tap the submit button without valid info. */
          />
        )}
      </div>
      <div className="modal-footer">
        <Button className="w-full" appearance="primary" size="large" disabled={hasErrors} onClick={onSubmit}>
          <span className="font-semibold">{submitButtonText}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default EnterYourInfoModal;
