import React from 'react';

import SVG, { svgProps } from './svg';

const ExpandArrows = ({
  className,
  height = '12',
  title = 'Expand',
  width = '12',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 12 12"
  >
    <path fill="currentColor" d="M2.236 11l-.131-.008c-.53-.062-.96-.455-1.075-.978l-.023-.133L1 9.75V5.99l.008-.14c.073-.627.606-1.1 1.242-1.1.594 0 1.097.413 1.222.99l.02.13L3.5 6l.001 2.5H6.01l.14.008c.627.073 1.1.606 1.1 1.242 0 .594-.413 1.097-.99 1.222l-.13.02L6 11H2.236zM9.75 7.25c-.594 0-1.097-.413-1.222-.99l-.02-.13L8.5 6l.001-2.5H5.99l-.14-.008c-.627-.073-1.1-.606-1.1-1.242 0-.594.413-1.097.99-1.222l.13-.02L6 1h3.764l.131.008c.53.062.96.455 1.075.978l.023.133.007.131v3.76l-.008.14c-.073.627-.606 1.1-1.242 1.1z"/>
  </SVG>
);

ExpandArrows.propTypes = svgProps;

export default ExpandArrows;
