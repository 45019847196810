import React from 'react';

import SVG, { svgProps } from './svg';

const GoogleLogo = ({
  className,
  height = '16',
  title = 'Google Logo',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M15.795 6.545H8.158v3.273h4.328c-.69 2.182-2.4 2.91-4.362 2.91-2.09.002-3.933-1.368-4.534-3.37-.6-2.001.185-4.16 1.93-5.308 1.746-1.148 4.04-1.014 5.64.33l2.377-2.266C10.903-.31 6.978-.69 3.928 1.186.878 3.06-.554 6.735.422 10.18c.976 3.445 4.121 5.822 7.702 5.82 4.411 0 8.4-2.91 7.671-9.455z"/>
      </g>
    </g>
  </SVG>
);

GoogleLogo.propTypes = svgProps;

export default GoogleLogo;
