import React from 'react';

import AvatarWho from '../avatar-who';
import CalendarSheet from '../calendar-sheet';

import CheckmarkIcon from '../icons/checkmark';

import { formatEventForList } from '../../utils/datefns';

import './availability-list.css';

const AvailabilityList = ({ options = [], participants = [], selectedTimes, onSelectTime }) => {
  return (
    <ul className="availability-list">
      {options.map(({ events }) => (
        events.map(({ id, start, end, responses = [] }) => (
          <li
            key={id}
            className="availability-list-item"
            onClick={() => onSelectTime(id)}
          >
            <CalendarSheet date={start} />
            <div className="availability-list-details">
              <span className="availability-list-date">
                {formatEventForList(start, end)}
              </span>
              <span className="availability-list-voted">
                {responses.length} Voted
              </span>
              <div className="availability-list-participants">
                {responses.map(({ userId }) => (
                  <AvatarWho
                    key={userId}
                    user={participants.find((p) => p.userId === userId).user}
                    showName={false}
                    initialsSize="0.625rem"
                    size="1.25rem"
                  />
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center">
              {selectedTimes.includes(id) ? (
                <CheckmarkIcon
                  className="text-green-weve"
                  height="1.25rem"
                  width="1.25rem"
                />
              ) : (
                <div className="availability-list-unselected" />
              )}
            </div>
          </li>
        ))
      ))}
    </ul>
  )
};

export default AvailabilityList;