import React from "react";

import IconWrapper from '../icons/wrapper';

import './avatar-main.css';

const AvatarMain = ({
  size = '5.25rem',
  initialsSize = '2rem',
  user = {},
}) => {
  const { givenName = '', familyName = '', picture } = user;
  const initials = `${givenName.charAt(0)}${familyName.charAt(0)}`;

  return (
    <div className="avatar-items-stack" title={givenName}>
      <IconWrapper size={size} >
        {picture ? (
          <img className="avatar" src={picture} />
        ) : (
          <span className="avatar-initials" style={{ fontSize: initialsSize }}>{initials}</span>
        )}
      </IconWrapper>
    </div>
  );
};

export default AvatarMain;
