import React from "react";

import Banner from './banner';
import Button from '../button';

import CalendarSingleHighlightIcon from '../icons/calendar-single-highlight';
import IconWrapper from '../icons/wrapper';

import { checkForiOS } from '../../utils/os';

const appleStoreURL = process.env.GATSBY_APPLE_STORE_URL;

const GetWeveBanner = () => {
  const isiOS = checkForiOS();

  if (isiOS) {
    return (
      <Banner>
        <div className="flex items-center justify-between">
          <IconWrapper size="4.125rem"><CalendarSingleHighlightIcon /></IconWrapper>
          <div className="flex-1 flex flex-col items-start px-3">
            <strong className="mb-1 text-sm">View with My Schedule</strong>
            <p className="leading-snug my-0 text-xs">See this event against your calendar with Weve.</p>
          </div>
          <Button as="a" size="small" href={appleStoreURL} rel="noreferrer">Get Weve</Button>
        </div>
      </Banner>
    );
  }

  return <div />
};

export default GetWeveBanner;
