import React from 'react';
import { isSameDay } from 'date-fns';

const MonthDateHeader = ({ date, label, events, selectedTimes }) => {
  const event = events.find((e) => isSameDay(e.start, date));
  const timesSelected = event ? event.events.filter((option) => selectedTimes.includes(option.id)).length : 0;

  return <div className={timesSelected ? 'has-selection' : ''}>{label}</div>;
};

export default MonthDateHeader;
