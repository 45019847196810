import React from 'react';

import SVG, { svgProps } from './svg';

const OutlookLogo = ({
  className,
  height = '16',
  title = 'Outlook Logo',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M9.99 3.392h5.374c.198 0 .548.27.548.645l-4.707 3.174c-.029.009-.092.008-.12 0L9.99 6.522v-3.13zm0 4.298l1 .685c.14.104.31 0 .31 0-.17.104 4.613-3.073 4.613-3.073v5.752c0 .626-.401.89-.852.89h-5.07V7.69zM4.824 6.247c.33 0 .59.155.781.465.19.31.286.745.286 1.303 0 .536-.1.955-.295 1.256-.196.302-.461.453-.796.453-.325 0-.587-.155-.787-.465-.2-.31-.3-.732-.3-1.266 0-.526.1-.948.3-1.267.2-.32.47-.48.81-.48zM.087 1.803L9.402 0v16L.087 14.047V1.803zM6.32 9.995c.394-.518.591-1.2.59-2.048 0-.823-.19-1.487-.57-1.99-.378-.504-.876-.755-1.49-.755-.652 0-1.174.263-1.57.789-.394.526-.591 1.214-.591 2.066 0 .806.195 1.46.583 1.962.389.502.891.753 1.508.753.634 0 1.147-.26 1.54-.777z"/>
      </g>
    </g>
  </SVG>
);

OutlookLogo.propTypes = svgProps;

export default OutlookLogo;
