import React from 'react';

import Button from '../button';
import StickyFooter from '../sticky-footer';

import { pluralize } from "../../utils/wording";

import './availability.css';

const AvailabilityFooter = ({ selectedTimes = 0, onSubmit }) => {
  const hasSelectedTimes = selectedTimes > 0;

  return (
    <StickyFooter>
      <div className="container max-w-2xl flex justify-center">
          <div className="availability-preface-wrapper"></div>
          <div className="availability-card-wrapper">
            <div>
              <Button
                className="availability-footer-button shadow-20" 
                appearance={hasSelectedTimes ? 'primary' : 'secondary'}
                size="medium"
                onClick={onSubmit}
              >
                <div className="flex flex-col items-center">
                  <span className="text-sm font-bold">Next</span>
                  <span className="text-xs font-medium">
                    {hasSelectedTimes ? `${pluralize(selectedTimes, 'Time Selected', 'Times Selected')}` : 'None of These Times Work'}
                  </span>
                </div>
              </Button>
            </div>
          </div>
          <div className="picker-modal-placeholder"></div>
      </div>
    </StickyFooter>
  );
};

export default AvailabilityFooter;
