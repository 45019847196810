import React from 'react';

import Button from '../button';

import BackArrowIcon from '../icons/back-arrow';
import ForwardArrowIcon from '../icons/forward-arrow';
import IconWrapper from '../icons/wrapper';

import { formatDate } from '../../utils/datefns';

const AvailabilityPickerToolbar = ({ date, onNavigate }) => {
  const label = formatDate(date, 'EEE, MMMM d, y');

  return (
    <div className="weve-calendar-toolbar availability-picker-toolbar">
      <Button appearance="icon" size="none" onClick={() => onNavigate(-1)}>
        <IconWrapper size="2.25rem">
          <BackArrowIcon className="text-green-weve" />
        </IconWrapper>
      </Button>
      <span className="flex-1 px-2 font-medium text-center">{label}</span>
      <Button appearance="icon" size="none" onClick={() => onNavigate(1)}>
        <IconWrapper size="2.25rem">
          <ForwardArrowIcon className="text-green-weve" />
        </IconWrapper>
      </Button>
    </div>
  );
};

export default AvailabilityPickerToolbar;
