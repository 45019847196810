import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { parse } from "query-string";

import AvatarWho from '../avatar-who';
import CalendarSheet from '../calendar-sheet';
import Card from '../card';
import EventDetails from '../event-details';
import GetWeveBanner from '../banner/get-weve';
import Loader from '../loader';
import RsvpButton from '../button/rsvp';
import SEO from '../seo';

import AddToCalendarModal from '../add-to-calendar-modal';
import EnterYourInfoModal from '../enter-your-info-modal';

import CalendarCheckIcon from '../icons/calendar-check';
import CheckmarkIcon from '../icons/checkmark';
import DeclineCrossIcon from '../icons/decline-cross';
import LocationPinIcon from '../icons/location-pin';
import QuestionMarkIcon from '../icons/question-mark';
import IconWrapper from '../icons/wrapper';

import { getEvent, updateEventParticipant } from '../../api/event';
import { formatEventDate, getDuration } from '../../utils/datefns';

import './rsvp.css';
import '../Availability/availability.css';
import CardTimeZone from "../card/card-time-zone";

function convertRsvpText(rsvp) {
  switch (rsvp) {
    case 'Accepted':
      return 'Going';
    case 'Tentative':
      return 'Maybe';
    case 'Rejected':
      return "Can't Go";
    default:
      return '';
  }
}

const Rsvp = () => {
  const location = useLocation();
  const [event, setEvent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddCalendarModalOpen, setIsAddCalendarModalOpen] = useState(false);
  const [isEnterYourInfoModalOpen, setIsEnterYourInfoModalOpen] = useState(false);
  const [rsvp, setRsvp] = useState('');
  const [contactName, setContactName] = useState('');
  const [isEmail, setIsEmail] = useState(false);
  const [contactData, setContactData] = useState();

  useEffect(() => {
    const { eventId, userId } = parse(location.search);
    getEvent(eventId, userId)
      .then((response) => {
        setIsLoading(false);
        setEvent(response.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const acceptedParticipants = (event ? event.participants : []).filter((participant) => participant.status === 'Accepted');
  const tentativeParticipants = (event ? event.participants : []).filter((participant) => participant.status === 'Tentative');
  const rejectedParticipants = (event ? event.participants : []).filter((participant) => participant.status === 'Rejected');
  const sentParticipants = (event ? event.participants : []).filter((participant) => participant.status === 'Sent' && participant.userId !== 'multiLink');

  const filteredParticipants = [
    ...acceptedParticipants,
    ...tentativeParticipants,
    ...rejectedParticipants,
    ...sentParticipants,
  ]

  function openAddToCalendarModal() {
    setIsAddCalendarModalOpen(true);
  }

  function closeAddToCalendarModal() {
    setIsAddCalendarModalOpen(false);
  }

  function openEnterYourInfoModal(response) {
    setRsvp(response);
    setIsEnterYourInfoModalOpen(true);
  }

  function closeEnterYourInfoModal() {
    setIsEnterYourInfoModalOpen(false);
  }

  function submitRsvp() {
    const userId = isEmail ? contactData : encodeURIComponent(contactData);
    setIsLoading(true);

    updateEventParticipant(event.id, userId, contactName, rsvp)
      .then(({ data }) => {
        setIsLoading(false);
        closeEnterYourInfoModal();
        if (rsvp !== 'Rejected') {
          openAddToCalendarModal();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
     <SEO title="RSVP" />
     <div className="container" style={{flex: '0'}}>
        <div className="availability-header-label">
          <a href="https://www.weve.app" cursor="pointer" className="text-2xl font-medium text-green-weve"><span>weve.</span></a>
            <span className="text-2xl font-light text-greyish-2">rsvp</span>
        </div>
      </div>
      <div className="container rsvp-max-w-2xl-override py-4">
        <GetWeveBanner />
        <div className="flex justify-center mt-4">
          <IconWrapper
            size="5.25rem"
            /*badge={<IconWrapper bg="#00cc96" size="1.5rem"><ShareAirplaneIcon /></IconWrapper>} *commented out for formatting cleanup* */
          >
            <CalendarCheckIcon />
          </IconWrapper>
        </div>
        {event && (
          <>
            <EventDetails event={event} expanded openAddToCalendarModal={openAddToCalendarModal} />
            <div className="rsvp-actions">
              <RsvpButton appearance="decline" onClick={() => openEnterYourInfoModal('Rejected')}><DeclineCrossIcon className="mb-1" />Can't Go</RsvpButton>
              <RsvpButton appearance="maybe" onClick={() => openEnterYourInfoModal('Tentative')}><QuestionMarkIcon className="mb-1" />Maybe</RsvpButton>
              <RsvpButton appearance="accept" onClick={() => openEnterYourInfoModal('Accepted')}><CheckmarkIcon className="mb-1" />Going</RsvpButton>
            </div>
            <Card title={`Who? (${filteredParticipants.length + 1})`}>
              <div className="participant-list">
                <AvatarWho user={event.owner} isOwner />
                {filteredParticipants.map((participant) => (
                  <AvatarWho key={participant.userId} user={participant.user || participant} response={participant.status} />
                ))}
              </div>
            </Card>
            <Card title="Where?">
              <div className="flex items-center">
                <IconWrapper size="3.5rem">
                  <LocationPinIcon className="text-green-weve" />
                </IconWrapper>
                <div className="flex-1 pl-3">
                  {Object.keys(event.structuredLocation).length ? (
                    <>
                      <p className="my-0 text-sm font-medium">{event.structuredLocation.name}</p>
                      <p className="my-0 text-sm font-medium text-greyish-2">{event.structuredLocation.address}</p>
                    </>
                  ) : (
                    <p className="my-0 text-sm font-medium text-greyish-2">No Location</p>
                  )}
                </div>
              </div>
            </Card>
            <CardTimeZone title="When?">
              <div className="flex items-center">
                <CalendarSheet date={event.startDate} />
                <div className="flex-1 pl-3">
                  {formatEventDate(event.startDate, event.endDate, event.isAllDay).map((line, index) => (
                    <p key={line} className={`my-0 text-sm font-medium${index === 1 ? ' text-greyish-2' : ''}`}>
                      {line}{index === 1 ? ` (${getDuration(event.startDate, event.endDate)})` : ''}
                    </p>
                  ))}
                </div>
              </div>
            </CardTimeZone>
            <Card title="Notes">
              <div className="flex items-center">
                <div className="flex-1">
                  <p className="my-0 text-sm font-medium text-greyish-2">{event.description || 'No Notes'}</p>
                </div>
              </div>
            </Card>
            <AddToCalendarModal
              event={event}
              isOpen={isAddCalendarModalOpen}
              closeModal={closeAddToCalendarModal}
            />
            <EnterYourInfoModal
              isOpen={isEnterYourInfoModalOpen}
              closeModal={closeEnterYourInfoModal}
              contactName={contactName}
              setContactName={setContactName}
              isEmail={isEmail}
              setIsEmail={setIsEmail}
              contactData={contactData}
              setContactData={setContactData}
              onSubmit={submitRsvp}
              submitButtonText={`RSVP (${convertRsvpText(rsvp)})`}
            />
          </>
        )}
        {isLoading && <Loader />}
      </div>
    </>
  );
};

export default Rsvp;
