import React from 'react';
import { Navigate } from 'react-big-calendar';

import Button from '../button';

import BackArrowIcon from '../icons/back-arrow';
import ForwardArrowIcon from '../icons/forward-arrow';
import IconWrapper from '../icons/wrapper';

const CalendarToolbar = ({ label, onNavigate }) => {
  return (
    <div className="weve-calendar-toolbar">
      <Button appearance="icon" size="none" onClick={() => onNavigate(Navigate.PREVIOUS)}>
        <IconWrapper size="2.25rem">
          <BackArrowIcon className="text-green-weve" />
        </IconWrapper>
      </Button>
      <span className="flex-1 px-2 font-medium text-center">{label}</span>
      <Button appearance="icon" size="none" onClick={() => onNavigate(Navigate.NEXT)}>
        <IconWrapper size="2.25rem">
          <ForwardArrowIcon className="text-green-weve" />
        </IconWrapper>
      </Button>
    </div>
  );
};

export default CalendarToolbar;
