import React from "react";

import './banner.css';

const Banner = ({ children }) => {
  return (
    <div className="banner">{children}</div>
  );
};

export default Banner;
