import React from 'react';

import SVG, { svgProps } from './svg';

const CalendarForward = ({
  className,
  height = '16',
  title = 'Calendar Forward',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="#ffffff" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path d="M12 0c1.657 0 3 1.343 3 3v4.39c-.457-.299-.961-.53-1.5-.683V5c0-.245-.177-.45-.41-.492L13 4.5H2c-.245 0-.45.177-.492.41L1.5 5v7c0 .78.595 1.42 1.356 1.493L3 13.5h3.707c.152.539.384 1.043.683 1.5H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h9zm0 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm-.19 1.47c-.271.256-.312.657-.11.96l.51.835-2.21-.013c-.414 0-.75.336-.75.75 0 .381.285.695.65.745l.1.007 2.21-.013-.45.75c-.27.301-.25.765.05 1.04.286.261.697.29.97.08l.08-.07 1.688-2.027c.243-.26.267-.647.07-.936l-.074-.092L12.87 9.48c-.278-.298-.738-.311-1.06-.01zm-7.31.28c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm3-3c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75zm-3 0c.414 0 .75.336.75.75s-.336.75-.75.75-.75-.336-.75-.75.336-.75.75-.75z" transform="translate(-209 -237) translate(0 154) translate(146 23) translate(55 52) translate(8 8)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SVG>
);

CalendarForward.propTypes = svgProps;

export default CalendarForward;
