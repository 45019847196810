import React from "react";

import './rsvp.css';

const RsvpButton = ({
  children,
  className = '',
  appearance = '',
  ...props
}) => {
  return (
    <button className={`rsvp-button ${appearance} ${className}`} {...props}>{children}</button>
  );
};

export default RsvpButton;
