import React from "react";

import './card.css';

const Card = ({
  bodyPadding = true,
  children,
  title = ''
}) => {
  return (
    <div className="card">
      <div className="card-header">{title}</div>
      <div className={`card-body ${bodyPadding ? 'padded' : ''}`}>{children}</div>
    </div>
  );
};

export default Card;
