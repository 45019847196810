import React from "react";

import { formatEventDate, isTheSameDay } from '../../utils/datefns';
import { getOwnerName } from '../../utils/naming';

const EventDetails = ({ event = {}, expanded, openAddToCalendarModal }) => {
  return (
    <div className="flex flex-col items-center my-4">
      <h2 className={`font-medium my-0 text-center ${expanded ? 'text-xl' : 'text-sm'}`}>
        {event.title}
      </h2>
      {expanded && (
        <span className="text-sm font-medium text-greyish">
          From: {getOwnerName(event.owner)}
        </span>
      )}
      <span className="text-sm font-medium text-greyish text-center">
        {formatEventDate(event.startDate, event.endDate, event.isAllDay).join(isTheSameDay(event.startDate, event.endDate) ? ', ' : ' - ')}
      </span>
      {openAddToCalendarModal && (
        <a
          className="pointer text-sm font-medium text-green-weve"
          tabIndex="0"
          onClick={openAddToCalendarModal}
        >
          Add to your calendar
        </a>
      )}
    </div>
  );
};

export default EventDetails;
