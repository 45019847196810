import React from 'react';

import SVG, { svgProps } from './svg';

const CalendarCheckPoll = ({
  className,
  height = '16',
  title = 'Calendar Check Poll',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 32 32"
  >
    <g fill="none" fill-rule="evenodd">
        <g fill="#ffffff" fill-rule="nonzero">
            <g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path d="M1.5 32C.672 32 0 31.328 0 30.5v-29C0 .672.672 0 1.5 0S3 .672 3 1.5v29c0 .828-.672 1.5-1.5 1.5zM23 10H8c-1.105 0-2-.895-2-2V6c0-1.105.895-2 2-2h15c1.105 0 2 .895 2 2v2c0 1.105-.895 2-2 2zm7 9H8c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h22c1.105 0 2 .895 2 2v2c0 1.105-.895 2-2 2zm-14 9H8c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h8c1.105 0 2 .895 2 2v2c0 1.105-.895 2-2 2z" transform="translate(-4935 -1212) translate(200 371) translate(2438 143) translate(1641 48) translate(0 604) translate(0 46) translate(656)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </SVG>
);

CalendarCheckPoll.propTypes = svgProps;

export default CalendarCheckPoll;
