import React from 'react';

const MonthEvent = ({ event, selectedTimes }) => {
  const timesSelected = event.events.filter((option) => selectedTimes.includes(option.id)).length;

  return (
    <div className={`weve-calendar-month-event ${timesSelected ? 'has-selection' : ''}`}>
      {timesSelected === 0 ? event.title : `${timesSelected} of ${event.events.length}`}
    </div>
  );
};

export default MonthEvent;
