import React from 'react';

import SVG, { svgProps } from './svg';

const YahooLogo = ({
  className,
  height = '16',
  title = 'Yahoo Logo',
  width = '16',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 16 16"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M15.526 5.047c-.279.027-1.438.287-1.828.37-.417.112-4.228 3.056-4.478 3.779-.056.25-.084.635-.084.997l-.027.584c0 .417.115 1.09.17 1.451.251.056 2.065.007 2.399.063l-.042.748c-.326-.024-2.628-.019-3.944-.019-.668 0-2.815.074-3.474.054l.125-.71c.361-.029 1.859.064 2.188-.284.164-.172.111-.357.111-1.358v-.473c0-.223 0-.64-.055-1.03-.14-.417-3.496-4.608-4.359-5.276-.25-.083-1.82-.24-2.209-.324L0 2.98c.194-.098 1.939.023 3.632-.04 1.113-.04 3.652 0 3.965.038l-.08.564c-.334.084-1.941.115-2.359.226 1.085 1.613 2.8 3.69 3.357 4.497.306-.445 2.996-2.295 3.079-2.935-.417-.083-1.8-.282-2.022-.282l-.132-.729c.379-.059 2.37 0 3.36 0 .855 0 2.681 0 3.2.043l-.474.686"/>
      </g>
    </g>
  </SVG>
);

YahooLogo.propTypes = svgProps;

export default YahooLogo;
