import React from 'react';

import SVG, { svgProps } from './svg';

const Crown = ({
  className,
  height = '8',
  title = 'Crown',
  width = '12',
}) => (
  <SVG
    className={className}
    title={title}
    height={height}
    width={width}
    viewBox="0 0 12 8"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path d="M0 0H12V12H0z" transform="translate(-4 -6) translate(4 4)"/>
          <path fill="currentColor" fillRule="nonzero" d="M11.335 2.19L8.286 6 6 2.19 3.714 6 .665 2.19v6.668c0 .526.427.953.953.953h8.764c.526 0 .953-.427.953-.953V2.189z" transform="translate(-4 -6) translate(4 4)"/>
        </g>
      </g>
    </g>
  </SVG>
);

Crown.propTypes = svgProps;

export default Crown;
